export default function Employee(attrs) {
  this.photo_url = attrs.photo_url ? attrs.photo_url : ''
  this.name = attrs.name ? attrs.name : ''
  this.phone = attrs.phone ? attrs.phone : ''
  this.store_id = attrs.store_id ? attrs.store_id : null
  this.pos_id = attrs.pos_id ? attrs.pos_id : null
  this.role = attrs.role ? attrs.role : ''
  this.role_id = attrs.role_id ? attrs.role_id : ''
  this.login = attrs.login ? attrs.login : ''
  this.show_in_list = attrs.show_in_list ? attrs.show_in_list : true
  this.target_text = attrs.target_text ? attrs.target_text : ''
}
