<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <a-input
          v-model:value="employeeName"
          placeholder="Введите имя или номер телефона"
          @change="searchEmployee"
          allow-clear
        />
      </div>
      <div class="col-md-2">
        <a-select
          v-model:value="brandId"
          :options="brands"
          :option-filter-prop="'label'"
          placeholder="Выберите бренд"
          style="width: 100%"
          @change="searchBrand"
          show-search
          allow-clear
        />
      </div>
      <div class="col-md-2">
        <a-select
          v-model:value="storeId"
          :options="stores"
          :option-filter-prop="'label'"
          placeholder="Выберите филиал"
          style="width: 100%"
          @change="searchStore"
          show-search
          allow-clear
        />
      </div>
      <div class="text-right col-md-5">
        <a-button
          v-if="user.role === 'admin'"
          type="link"
          style="color: #ff4d4f"
          @click="router.push({ name: 'employee-archived' })"
        >
          <template #icon><DeleteOutlined /></template>
          <span>Архив сотрудников</span>
        </a-button>
        <vb-create-employee
          :roles="roles"
          :stores="stores"
          @reload="reload()"
        />
      </div>
    </div>

    <vb-edit-employee
      v-if="modalVisible"
      :employee-id="employeeId"
      :roles="roles"
      :stores="stores"
      @reload="reload()"
      @hide-modal="hideModal()"
    />
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="tableChange"
    >
      <template #name="{ record }">
        <div class="d-flex flex-column">
          <div>
            <div
              v-if="record.photo_url === '' || record.photo_url == null"
              class="employee-photo"
            >
              <img
                width="47"
                src="https://cdn.multicard.uz/storage/29e/e8576059ab6c74182ab7797992857.png"
                alt=""
              />
            </div>
            <div
              v-else
              :style="'background-image: url(\'' + record.photo_url + '\')'"
              class="employee-photo"
            ></div>
            <span class="employee-name">
              {{ record.name }}
            </span>
          </div>
          <a-tooltip placement="topRight" class="mx-2">
            <template #title>{{ record.target_text }}</template>
            <div class="text-target">{{ record.target_text }}</div>
          </a-tooltip>
        </div>
      </template>
      <template #wallet="{ record }">
        <div v-if="record.wallet" class="w-100 text-center">
          {{
            record.wallet.type === 'personal'
              ? formattedBalance(record.wallet.balance)
              : ''
          }}
        </div>
      </template>
      <template #points="{ record }">
        <div class="w-100 text-center">
          {{ record.points }}
        </div>
      </template>
      <template #store="{ record }">
        <span>
          {{ record.store.name }}
        </span>
      </template>
      <template #role="{ record }">
        {{ record['employeeRole']?.name }}
      </template>
      <template #invite_sent="{ record }">
        <a-tooltip v-if="record['invite_sent']" :title="record['invite_sent']">
          <a-tag color="success">Отправлено</a-tag>
        </a-tooltip>
        <a-tag color="default" v-else>Не отправлено</a-tag>
      </template>
      <template #phone="{ text }">
        {{ formattedPhone(text) }}
      </template>
<!--      <template #date="{ text }">-->
<!--        <span>-->
<!--          {{ new Date(text).toLocaleDateString() }}-->
<!--          {{ new Date(text).toLocaleTimeString() }}-->
<!--        </span>-->
<!--      </template>-->
      <template #date="{ record }">
        <span>
          {{ formattedDatetime(record['added_on']) }}
        </span>
        <span v-if="record.addedby?.username">
          ({{ record.addedby.username }})
        </span>
      </template>
      <template #updated="{ record }">
        <span v-if="record['updated_on']">
          {{ formattedDatetime(record['updated_on']) }}
        </span>
        <span v-if="record.updatedby?.username">
          ({{ record.updatedby.username }})
        </span>
      </template>
      <template #qr="{ record }">
        <a
          :href="record['short_link']"
          target="_blank"
          style="max-height: 55px; max-width: 55px; display: block"
        >
          <img
            style="max-width: 100%; max-height: 100%"
            :src="record['qr']"
            alt=""
          />
        </a>
      </template>
      <template #action="{ record }">
        <div class="text-right d-flex align-items-center">
          <a-tooltip
            placement="top"
            class="mx-2"
            v-if="user.role === 'admin'"
          >
            <template #title>Зачислить бонус</template>
            <a-button
              shape="circle"
              @click="showBonusModal(record)"
              type="text"
              :style="{ margin: '0 !important' }"
            >
              <template #icon><i class="fe fe-dollar-sign"></i></template>
            </a-button>
          </a-tooltip>
          <a-tooltip placement="top" class="mx-2">
            <template #title>Обновить QR-код</template>
            <a @click="showQrModal(record['id'], record['qr'], record['short_link'])"><i class="fe fe-qr" style="font-size: 16px"><qrcode-outlined /></i></a>
          </a-tooltip>
          <a-tooltip>
            <template #title><div class="text-center">Отправить приглашение<br /> в бот по SMS</div></template>
            <a-popconfirm
              :title="`Отправить приглашение сотруднику ${record.name}?`"
              @confirm="sendInvite(record.id)"
              placement="left"
              ok-text="Отправить"
              cancel-text="Нет"
            >
              <a-button
                shape="circle"
                :loading="inviteLoading === record.id"
                type="text"
                :style="{ margin: '0 !important' }"
              >
                <template #icon
                  ><i v-if="inviteLoading !== record.id" class="fe fe-send"></i
                ></template>
              </a-button>
            </a-popconfirm>
          </a-tooltip>
          <a-tooltip
            placement="top"
            class="mx-2"
            v-if="user.role === 'admin'"
          >
            <template #title>Платежи</template>
            <a-button
              shape="circle"
              @click="goToReports(record.id)"
              type="text"
              :style="{ margin: '0 !important' }"
            >
              <template #icon><i class="fe fe-clipboard"></i></template>
            </a-button>
          </a-tooltip>
          <a-tooltip placement="topRight" class="mx-2">
            <template #title>Редактировать сотрудника</template>
            <a @click="showModal(record.id)"><i class="fe fe-edit"></i></a>
          </a-tooltip>
          <a-tooltip placement="topRight" class="mx-2">
            <template #title>Удалить сотрудника</template>
            <a @click="showDeleteModal(record.id)"><i class="fe fe-trash"></i></a>
          </a-tooltip>
        </div>
      </template>
    </a-table>
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal
      v-model:visible="deleteModal"
      @cancel="hideDeleteModal"
      :footer="null"
    >
      <a-typography-title :level="5">Вы уверены что хотите удалить сотрудника?</a-typography-title>
      <div class="footer overflow-hidden pt-2">
        <a-button @click="deleteEmployee" :loading="deleteLoading" type="primary" class="float-right">Да</a-button>
        <a-button @click="hideDeleteModal" type="secondary" class="float-right mx-2">Нет</a-button>
      </div>
    </a-modal>
    <a-modal
      :width="400"
      ok-text="Зачислить"
      title="Зачислить бонус"
      v-model:visible="bonusModalVisible"
      :confirm-loading="employeeBonusLoading"
      @ok="income(employeeBonusEmployee.id)"
      @cancel="hideBonusModal"
    >
      <a-typography-paragraph>
        <strong>Сотрудник:</strong>
        <span>&nbsp;</span>
        <span>{{ employeeBonusEmployee?.name }}</span>
      </a-typography-paragraph>
      <a-typography-paragraph>
        <strong>Номер телефона:</strong>
        <span>&nbsp;</span>
        <span>{{ formattedPhone(employeeBonusEmployee?.phone) }}</span>
      </a-typography-paragraph>
      <a-input
        v-model:value="employeeBonus"
        placeholder="Введите сумму"
        type="number"
      />
      <div class="code-box-meta markdown mt-5">
        <a href="#" class="code-box-title">
          <span>История пополнений</span>
        </a>
<!--        <div v-if="bonusHistoryLoading" class="w-100 text-center">-->
<!--          <a-spin />-->
<!--        </div>-->
        <div>
          <a-list
            bordered
            :loading="bonusHistoryLoading"
            :data-source="bonusHistory"
          >
            <template #renderItem="{ item }">
              <a-list-item class="px-3">
                <a-list-item-meta :description="item.added_on">
                  <template #title>
                    {{ formattedBalance(item.amount, true) + ' UZS' }}
                  </template>
                  <template #avatar>
                    <a-avatar :size="50">
                      <template #icon><DollarOutlined /></template>
                    </a-avatar>
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </template>
          </a-list>
        </div>
      </div>
    </a-modal>
    <a-modal
      title="Обновить QR-код"
      v-model:visible="qrModalVisible"
      @cancel="hideQrModal"
      :width="300"
    >
      <template #footer>
        <a-button @click.prevent="qrGenerate" type="link">Сгенерировать</a-button>
        <a-button @click.prevent="qrUpdate" type="primary">Обновить</a-button>
      </template>
      <div
        v-if="employeeQrLoading"
        style="width: 250px; height: 250px; display: flex; flex-direction: column; justify-content: center;"
      >
        <a-spin />
      </div>
      <img v-else :src="employeeQr" width="250" alt="">
      <a-form layout="vertical">
        <a-form-item name="code" label="Код">
          <a-input v-model:value="employeeCode" placeholder="Введите код" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, toRefs, watch } from "vue";
import { QrcodeOutlined, DeleteOutlined, DollarOutlined } from "@ant-design/icons-vue";
import VbCreateEmployee from "@/views/employee/create";
import { message, notification } from "ant-design-vue";
import VbEditEmployee from "@/views/employee/edit";
import { useRoute, useRouter } from "vue-router";
import { default as localStore } from "store";
import apiClient from "@/services/axios";
import { useStore } from "vuex";
import { mask } from "maska";

let employeeName = ref(''),
  storeId = ref(null),
  brandId = ref(null),
  dataSource = ref([]),
  deleteModal = ref(false),
  deleteId = ref(0),
  deleteLoading = ref(false),
  loading = ref(false),
  inviteLoading = ref(0),
  modalVisible = ref(false),
  employeeTimer = null,
  total = ref(0),
  employeeId = ref(0),
  employeeQrId = ref(0),
  employeeBonusEmployee = ref(null),
  employeeBonusLoading = ref(false),
  employeeBonus = ref(null),
  bonusModalVisible = ref(false),
  bonusHistory = ref([]),
  bonusHistoryLoading = ref(false),
  employeeQrLoading = ref(false),
  employeeCode = ref(null),
  employeeQr = ref(null),
  qrModalVisible = ref(false),
  roles = ref([]),
  stores = ref([]),
  brands = ref([]);

const router = useRouter(),
  store = useStore(),
  filters = computed(() => store.getters.filters),
  user = computed(() => useStore().getters['user/user']),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 10,
    sort: '',
    name: ''
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'Имя сотрудника',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      fixed: 'left',
      width: 300,
      slots: {
        customRender: 'name'
      }
    },
    {
      title: 'Филиал',
      dataIndex: 'store_id',
      key: 'store_id',
      sorter: true,
      width: 230,
      slots: {
        customRender: 'store'
      }
    },
    {
      title: 'Роль',
      dataIndex: 'role_id',
      sorter: true,
      key: 'role',
      width: 120,
      slots: {
        customRender: 'role'
      }
    },
    {
      title: 'Номер телефона',
      dataIndex: 'phone',
      sorter: true,
      width: 180,
      key: 'phone',
      slots: {
        customRender: 'phone'
      }
    },
    {
      title: 'Telegram ID',
      dataIndex: 'login',
      width: 140,
      key: 'login'
    },
    // {
    //   title: 'Статус отправки СМС',
    //   dataIndex: 'invite_sent',
    //   width: 240,
    //   key: 'invite_sent',
    //   slots: {
    //     customRender: 'invite_sent'
    //   }
    // },
    {
      title: 'Баланс',
      dataIndex: 'wallet',
      sorter: false,
      width: 140,
      key: 'wallet',
      slots: {
        customRender: 'wallet'
      }
    },
    {
      title: 'Рейтинг',
      dataIndex: 'points',
      sorter: true,
      width: 140,
      key: 'points',
      slots: {
        customRender: 'points'
      }
    },
    {
      title: 'QR',
      dataIndex: 'qr',
      sorter: false,
      key: 'qr',
      width: 140,
      slots: {
        customRender: 'qr'
      }
    },
    {
      title: 'Добавлен',
      dataIndex: 'added_on',
      key: 'added_on',
      width: 140,
      slots: {customRender: 'date'},
    },
    {
      title: 'Редактирован',
      dataIndex: 'updated_on',
      key: 'updated_on',
      width: 140,
      slots: {customRender: 'updated'},
    },
    {
      key: 'action',
      fixed: 'right',
      slots: { customRender: 'action' },
      width: 220
    }
  ],
  formattedDatetime = (date) => {
    date = date.replace(/ /g, "T")
    let d = new Date(date)
    const timeFormatter = new Intl.DateTimeFormat('ru-RU', {
      timeStyle: 'medium',
    })
    const dateFormatter = new Intl.DateTimeFormat('ru-RU')
    return dateFormatter.format(d) + ' ' + timeFormatter.format(d)
  },
  updateParams = params => {
    Object.assign(queryParams, params);
    Object.keys(params).forEach((queryParam) => {
      switch (queryParam) {
        case 'brand_id':
          brandId.value = params.brand_id;
          break;
        case 'name':
          employeeName.value = params.name;
          break;
        case 'store_id':
          storeId.value = params.store_id;
          break;
      }
    })
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == 'string') {
      let sign = '';
      if (sorter.order === 'descend') sign = '-';
      updateParams({ sort: sign + sorter.field });
    }
  },
  searchEmployee = e => {
    if (e.target.value.length > 0 && e.target.value.length < 3) return;
    if (employeeTimer) {
      clearTimeout(employeeTimer);
      employeeTimer = null;
    }
    employeeTimer = setTimeout(() => {
      updateParams({ name: e.target.value });
    }, 800);
  },
  goToReports = id => {
    router.push({ name: 'report', query: { employee_id: id } });
    localStore.set('app.menu.selectedKeys', ['__report']);
  },
  searchStore = e => {
    updateParams({ store_id: e });
  },
  searchBrand = e => {
    updateParams({ brand_id: e });
  },
  showBonusModal = (employee) => {
    employeeBonusEmployee.value = employee
    bonusModalVisible.value = true
    employeeBonus.value = null
  },
  hideBonusModal = () => {
    employeeBonusEmployee.value = null
    bonusModalVisible.value = false
    employeeBonus.value = null
  },
  income = (id) => {
    employeeBonusLoading.value = true
    apiClient.post('employee/income?id=' + id + '&amount=' + employeeBonus.value, {
      id: employeeQrId.value,
      code: employeeCode.value
    }).then(({ data }) => {
      if (data.data) {
        notification.success({
          message: 'Бонус успешно зачислен сотруднику'
        })
      } else {
        notification.success({
          message: 'Не удалось зачислить бонус'
        })
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      employeeBonusLoading.value = false
      hideBonusModal()
      reload()
    })
  },
  showQrModal = (id, qr, link) => {
    employeeQrId.value = id
    employeeQr.value = qr
    employeeCode.value = link ? link.split('/').at(-1) : null
    qrModalVisible.value = true;
  },
  hideQrModal = () => {
    employeeQrId.value = 0
    employeeCode.value = null
    employeeQr.value = null
    qrModalVisible.value = false;
  },
  qrGenerate = () => {
    employeeQrLoading.value = true
    apiClient.post('employee/generate-qr?id=' + employeeQrId.value).then(({ data }) => {
      employeeCode.value = data.data['code']
      employeeQr.value = data.data['qr']
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      employeeQrLoading.value = false
      reload()
    })
  },
  qrUpdate = () => {
    if (!employeeCode.value || employeeCode.value.length < 1) {
      notification.warning({
        message: 'Пожалуйста заполните поле "код"'
      })
      return;
    }
    employeeQrLoading.value = true
    apiClient.post('employee/update-qr', {
      id: employeeQrId.value,
      code: employeeCode.value
    }).then(({ data }) => {
      if (data.data) {
        employeeQr.value = data.data
        notification.success({
          message: 'QR-код успешно обновлен'
        })
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      employeeQrLoading.value = false
      hideQrModal()
      reload()
    })
  },
  showModal = id => {
    employeeId.value = id;
    employeeCode.value = null;
    modalVisible.value = true;
  },
  hideModal = () => {
    setTimeout(() => {
      modalVisible.value = false;
    }, 100);
  },
  showDeleteModal = id => {
    deleteModal.value = true
    deleteId.value = id
  },
  hideDeleteModal = () => {
    deleteLoading.value = false
    deleteModal.value = false;
    deleteId.value = 0
  },
  deleteEmployee = () => {
    deleteLoading.value = true
    return apiClient.delete(`employee/${deleteId.value}`).then(response => {
      if (response) {
        message.success('Сотрудник успешно удален');
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      hideDeleteModal()
      reload()
    });
  },
  sendInvite = id => {
    inviteLoading.value = id;
    apiClient.post(`/employee/invite?id=${id}`).then(() => {
      inviteLoading.value = 0;
    }).catch(() => {
      // console.log(err);
    }).finally(() => {
      inviteLoading.value = 0;
    });
  },
  formattedPhone = phone => {
    return phone ? mask(phone, '+998(##) ###-##-##') : '';
  },
  formattedBalance = balance => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS'
    });
    let formattedBalance = formatter.format(balance / 100);
    return formattedBalance.substring(0, formattedBalance.length - 4);
  },
  getData = params => {
    loading.value = true;
    return apiClient.get('/employee', { params }).then(({ data }) => {
      dataSource.value = data.data.items;
      total.value = data.data.total;
      stores.value = data.data.stores;
      brands.value = data.data.brands;
      roles.value = data.data.roles;
    }).catch(() => {
      // console.log(err);
    }).finally(() => {
      loading.value = false
      store.commit('CHANGE_FILTER', { page: 'employee', value: params })
    });
  },
  reload = () => {
    getData({
      ...queryParams
    });
  };

const getStateParams = () => {
  let stateParams = {}
  if (filters.value.employee) {
    stateParams = filters.value.employee
    Object.keys(stateParams).forEach(paramKey => {
      let _params = {}
      _params[paramKey] = stateParams[paramKey]
      updateParams(_params)
    })
  }
}

onMounted(() => {
  getStateParams()

  if (route.query.store_id) {
    storeId.value = route.query.store_id;
    updateParams({store_id: route.query.store_id});
    return;
  }
  getData({
    ...queryParams
  });
});

watch(bonusModalVisible, (value) => {
  if (value) {
    bonusHistoryLoading.value = true
    apiClient.get('/employee/bonus-history?id=' + employeeBonusEmployee?.value?.id).then(({ data }) => {
      bonusHistory.value = data.data
    }).finally(() => {
      bonusHistoryLoading.value = false
    })
  }
})
watch(queryParams, () => {
  const params = {
    ...route.query,
    ...queryParams
  }
  router.push({
    name: 'employee',
    query: params
  });
  store.commit('CHANGE_FILTER', { page: 'employee', value: params })
  getData(params);
});

const query = { ...toRefs(queryParams) };
</script>

<style scoped>
@import './index.css';
</style>
