<template>
  <a-button class="editable-add-btn" @click="openModal" style="margin-bottom: 16px">Добавить сотрудника</a-button>
  <a-modal
    title="Добавить сотрудника"
    :visible="modalVisible"
    :closable="true"
    @cancel="resetForm"
  >
    <template #footer>
      <a-button key="cancel" @click="resetForm">Отмена</a-button>
      <a-button key="ok" type="primary" :loading="!(!loading && !confirmLoading)" @click="create">ОК</a-button>
    </template>
    <a-form v-model="employee" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-upload
        name="photo_url"
        list-type="picture-card"
        class="photo-uploader"
        :show-upload-list="false"
        :headers="getHeaders()"
        :action="getUrl"
        :before-upload="uploadValidation"
        @change="uploadImage"
      >
        <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
        <div v-else>
          <loading-outlined v-if="loading"></loading-outlined>
          <PlusOutlined v-else></PlusOutlined>
          <div class="ant-upload-text">Фото</div>
        </div>
      </a-upload>
      <a-form-item label="Имя сотрудника">
        <a-input
          ref="employeeName"
          placeholder="Введите имя пользователя"
          v-model:value="employee.name" />
      </a-form-item>
      <a-form-item label="Описание">
        <a-input
          placeholder="Введите описание"
          v-model:value="employee.target_text" />
      </a-form-item>
      <a-form-item label="Номер телефона">
        <a-spin v-if="loadingPhone" />
        <a-input
          v-else
          @input="updatePhone($event.target.value)"
          :value="phone"
          v-maska="'+998(##) ###-##-##'"
          @blur="onChangePhone"
          placeholder="Введите номер телефона"
        />
      </a-form-item>
      <a-form-item label="Роль">
        <a-select
          ref="role_id"
          v-model:value="employee.role_id"
          :options="roles"
          :option-filter-prop="'label'"
          placeholder="Выберите роль"
          show-search
        >
        </a-select>
      </a-form-item>
<!--      <a-form-item label="Доступ">-->
<!--        <a-radio-group v-model:value="employee.role" @change="onChangeAccess" button-style="solid" size="small">-->
<!--          <a-radio-button value="employee">Сотрудник</a-radio-button>-->
<!--          <a-radio-button value="manager">Менеджер</a-radio-button>-->
<!--          <a-radio-button value="driver">Водитель</a-radio-button>-->
<!--        </a-radio-group>-->
<!--      </a-form-item>-->
      <a-form-item label="Филиал">
        <a-select
          ref="store_id"
          show-search
          placeholder="Выберите филиал"
          v-model:value="employee.store_id"
          :options="stores"
          :filter-option="filter"
          @change="onChangeStore"
        />
      </a-form-item>
      <a-form-item :label="is_jowi ? 'ID сотрудника' : 'Привязка к POS'" v-if="showEmployeesSelect">
        <a-select
          :disabled="loadingEmployees"
          v-if="!is_jowi"
          ref="pos_id"
          :loading="loadingEmployees || loadingPos"
          show-search
          placeholder="Выберите сотрудника"
          v-model:value="employee.pos_id"
          :options="employees"
          @change="onChangePos"
          :filter-option="filter"
        />
        <a-input
          :disabled="loadingEmployees"
          v-else
          ref="pos_id"
          :loading="loadingEmployees"
          placeholder="Введите ID сотрудника"
          v-model:value="employee.pos_id"
        />
      </a-form-item>
      <a-form-item label="Показать в списке">
        <a-switch
          checked-children="Вкл"
          un-checked-children="Выкл"
          v-model:checked="employee.show_in_list" />
      </a-form-item>
    </a-form>
    <a-modal
      title="Перенос сотрудника"
      :visible="moveModal"
      @ok="moveEmployee"
      :confirm-loading="loading"
    >
      Указанный сотрудник уже существует в филиале {{ moveEmployeeModel?.['current_store'] }}. Хотите перенести его в филиал {{ moveEmployeeModel?.['new_store'] }}?
    </a-modal>
  </a-modal>
</template>

<script setup>
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { getBase64 } from "@/helpers/base64";
import { getHeaders } from "@/helpers/header"
import {ref, reactive, toRaw, nextTick} from "vue";
import apiClient from "@/services/axios";
import {message} from "ant-design-vue";
import Employee from "@/models/Employee";

const props = defineProps({
  stores: {
    type: Object,
    default() {
      return {}
    },
  },
  roles: {
    type: Object,
    default() {
      return {}
    },
  },
})

const emit = defineEmits(['reload'])

let imageUrl = ref(''),
  is_jowi = ref(false),
  loading = ref(false),
  loadingEmployees = ref(false),
  loadingPos = ref(false),
  loadingPhone = ref(false),
  showEmployeesSelect = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  moveModal = ref(false),
  moveEmployeeModel = ref(null),
  employeeName = ref("employeeName"),
  labelCol = { span: 9 },
  wrapperCol = { span: 13 },
  phone = ref(''),
  employees = ref([]),
  employee = reactive(new Employee({}));

const
  getEmployees = storeId => {
    loadingEmployees.value = true
    employee.pos_id = null
    apiClient.get(`/store/employees?store=${storeId}`).then(response => {
      if (response.data.data) {
        employees.value = response.data.data['employees']
        is_jowi.value = response.data.data['pos_type_name']
          ? response.data.data['pos_type_name'].toLowerCase() === 'jowi'
          : false
      } else {
        employees.value = []
        is_jowi.value = false
      }
      loadingEmployees.value = false
    }).catch(() => {
      // console.log(err)
      loadingEmployees.value = false
    }).finally(() => {
      loadingEmployees.value = false
    })
  },
  onChangePhone = () => {
    findByPhone()
  },
  findByPhone = () => {
    if (employee.phone.length > 11) {
      loadingPhone.value = true
      apiClient.get(`/employee/find?store=${employee.store_id}&phone=${employee.phone}`).then(({data}) => {
        if (data.data) {
          moveEmployeeModel.value = data.data
          moveModal.value = true
        }
      }).catch((err) => {
        employee.phone = ''
        phone.value = ''
      }).finally(() => {
        loadingPhone.value = false
      })
    }
  },
  onChangeStore = value => {
    showEmployeesSelect.value = true
    getEmployees(value)
    findByPhone()
  },
  onChangePos = value => {
    loadingPos.value = true
    apiClient.get(`/employee/find?store=${employee.store_id}&pos=${value}`).then(({data}) => {
      if (data.data) {
        moveEmployeeModel.value = data.data
        moveModal.value = true
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loadingPos.value = false
    })
  },
  moveEmployee = () => {
    loading.value = true;
    apiClient.put(`/employee/update?id=${moveEmployeeModel.value.id}`, {
      store_id: moveEmployeeModel.value['new_store_id'], method: 'move'
    }).then(response => {
      if (response) {
        message.success('Сотрудник успешно перенесен')
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false;
      modalVisible.value = false;
      moveModal.value = false;
      resetForm()
    })
  },
  filter = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  },
  uploadImage = info => {
    imageUrl.value = ''
    if (info.file.status === 'uploading') {
      loading.value = true;
      return;
    }

    if (info.file.status === 'done') {
      employee.photo_url = info.file.response.data
      getBase64(info.file.originFileObj, base64Url => {
        imageUrl.value = base64Url;
        loading.value = false;
      });
    }

    if (info.file.status === 'error') {
      loading.value = false;
      message.error('upload error');
    }
  },
  uploadValidation = file => {
    const isJpgOrPngOrSvg = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml';

    if (!isJpgOrPngOrSvg) {
      message.error('You can only upload JPG, PNG or SVG file!');
    }

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return isJpgOrPngOrSvg && isLt2M;
  },
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => employeeName.value?.focus());
  },
  getUrl = () => {
    return process.env.VUE_APP_BACKEND_URL + '/cabinet/employee/upload'
  },
  updatePhone = (input) => {
    let x = input
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    phone.value = input
    employee.phone = x[0];
  },
  resetForm = () => {
    phone.value = '';
    Object.assign(employee, new Employee({}));
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(employee);

    return apiClient.post('/employee', model).then(response => {
      if (response) {
        resetForm()
        modalVisible.value = false;
        confirmLoading.value = false;
        emit('reload')
      }
    }).catch(() => {
      modalVisible.value = false;
      confirmLoading.value = false;
      emit('reload')
      // console.log(err)
    }).finally(() => {
      confirmLoading.value = false;
      emit('reload')
    })
  };
</script>

<style scoped>
@import "./create.css";
</style>
